import React, { Component } from 'react';
import { Container } from 'reactstrap';
import Slider from '../../../components/SliderDesktop';
import Mapa from './Mapa';
import Featured from './Featured/Featured';
import Products from './Products/Products';
import FeaturedColor from './FeaturedColor/FeaturedColor';
import Posts from '../../../components/Posts';
import Box from '../../../components/Boxes';
import mas_informados_programa from '../../../assets/images/programa_mas_informados.gif';
import PesudoBanner from './PseudoBanner';

class Home extends Component {
  componentDidMount() {
    document.title = 'Sendero | Home';
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="home-page">
        <Slider />
        <Container>
          {/*<Box typeBox={'Featured'} type={2} />*/}
          <Box typeBox={'Products'} type={1} />

          {/* <img
            src={mas_informados_programa}
            alt="Mas Informados"
            class="img-fluid mt-5"
            style={{ cursor: 'pointer' }}
            onClick={(e) => {
              window.open('https://masinformados.sendero.cl/precios-sendero/', '_blanck');
            }}
          /> */}
          <PesudoBanner />
          <Mapa />
          {/*<Box typeBox={'Products'} type={1} />*/}
          {/*<Box typeBox={'FeaturedColorItem'} type={4} />*/}
        </Container>
        <Posts />
      </div>
    );
  }
}

export default Home;
