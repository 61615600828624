import React from 'react';
import { CodigoAgenteLibre } from '../../config';
import { Button, Alert } from 'reactstrap';
import { useState, useRef } from 'react';
import Validate from '../../service/Validate';
import Api from '../../service/Api';
import './fixed.scss';

const AgenteLibres = ({ props }) => {
  const initial = {
    Nombre: '',
    ApellidoP: '',
    ApellidoM: '',
    Rut: '',
    Email: '',
    Ext: '+56',
    Telefono: '',
    Mensaje: '',
    Motivo: '',
    Parque: '',
    type_form: 'Agente Libre',
    direccion: '',
    actual_worker_in_sendero: 1,
    worked_in_sendero: 1,
  };
  const [inputs, setInput] = useState({
    data: initial,
    txtMsj: '',
    AlertClass: '',
    disabledButton: false,
    nameButton: 'ENVIAR',
  });

  const style = {
    height: `150px ${' !important'}`,
  };

  const refAgenteLibre = useRef();

  if (CodigoAgenteLibre !== props?.match?.params?.id) {
    return <></>;
  }

  const handleonChange = (event) => {
    const { name } = event.target;
    let data = inputs.data;

    if (event.target.name === 'Rut') {
      data[name] = event.target.value
        .replace(/[.-]/g, '')
        .replace(/^(\d{1,2})(\d{3})(\d{3})(\w{1})$/, '$1.$2.$3-$4');
    } else {
      data[name] = event.target.value;
    }

    setInput({ ...inputs, data: data });
  };

  const setForm = async () => {
    if (inputs.data.Rut != '') {
      if (!Validate.validaRut(inputs.data.Rut.replaceAll('.', ''))) {
        setInput({
          ...inputs,
          txtMsj:
            'Por favor revise los siguientes campos:\n\n' +
            'El Rut ingresado no es válido.',
          AlertClass: 'alert-danger',
        });

        return false;
      }
    }

    let mensaje = '';
    let rules = {
      texto: 'Nombre',
      texto1: 'ApellidoP',
      texto2: 'ApellidoM',
      texto3: 'direccion',
      rut: 'Rut',
      email: 'Email',
      numerico: 'Telefono',
      extension: 'Ext',
      mensaje: 'Mensaje',
    };
    let validate = {};
    for (var [type, input] of Object.entries(rules)) {
      let resul = Validate.ValidateForm(
        inputs.data[input],
        type.replace(/[0-9]/g, ''),
        input
      );
      if (resul !== '') {
        mensaje += resul;
        validate[input] = [resul];
      }
    }
    Validate.validate(refAgenteLibre.current, validate);
    if (mensaje !== '') {
      setInput({
        ...inputs,
        txtMsj: 'Se encuentran campos con errores, favor revisar',
        AlertClass: 'alert-danger',
      });

      return false;
    }

    setInput({
      ...inputs,
      disabledButton: true,
      nameButton: 'CARGANDO..',
    });

    const response = await Api.sendEmailNotification(inputs.data);
    if (response.result === 'true') {
      setInput({
        ...inputs,
        data: initial,
        disabledButton: false,
        nameButton: 'ENVIAR',
      });

      setTimeout(() => {
        window.location = '/gracias-agente-libre/';
      }, 1000);
    } else {
      setInput({
        ...inputs,
        txtMsj: 'Ocurrió un problema, favor intentar nuevamente',
        AlertClass: 'alert-danger',
        disabledButton: false,
        nameButton: 'ENVIAR',
      });
    }
  };
  return (
    <>
      <label htmlFor="" style={{ marginBottom: '15px' }}>
        ¿Quiere ser un Agente Libre? Déjenos sus datos y nos contactaremos con
        usted
      </label>
      <form ref={refAgenteLibre}>
        <div className="row-form row">
          <div className="col-12 col-sm-12">
            <input
              className="form-control"
              type="text"
              name="Nombre"
              id="Nombre"
              placeholder="Nombre *"
              onChange={handleonChange}
              value={inputs.data.Nombre}
              title={''}
            />
          </div>
          <div className="col-12 col-sm-6">
            <input
              className="form-control"
              type="text"
              name="ApellidoP"
              id="ApellidoP"
              placeholder="Apellido Paterno *"
              onChange={handleonChange}
              value={inputs.data.Apellido}
              title={''}
            />
          </div>
          <div className="col-12 col-sm-6">
            <input
              className="form-control"
              type="text"
              name="ApellidoM"
              id="ApellidoM"
              placeholder="Apellido Materno*"
              onChange={handleonChange}
              value={inputs.data.Apellido}
              title={''}
            />
          </div>
          <div className="col-12 col-sm-6">
            <input
              className="form-control"
              type="text"
              name="Rut"
              id="Rut"
              placeholder="Rut *"
              onChange={handleonChange}
              value={inputs.data.Rut}
              title={''}
            />
          </div>
          <div className="col-12 col-sm-6">
            <input
              className="form-control"
              type="email"
              name="Email"
              id="Email"
              placeholder="Email *"
              onChange={handleonChange}
              value={inputs.data.Email}
              title={''}
            />
          </div>
          <div className="col-12 col-lg-12 col-md-12">
            <input
              className="form-control"
              type="direccion"
              name="direccion"
              id="direccion"
              placeholder="Dirección *"
              onChange={handleonChange}
              value={inputs.data.direccion}
              title={''}
            />
          </div>
          <div className="col-3 col-sm-3">
            <input
              className="form-control text-center"
              type="number"
              name="Ext"
              id="Ext"
              placeholder="+56"
              onChange={handleonChange}
              value="+56"
              disabled
              title={''}
            />
          </div>
          <div className="col-9 col-sm-9">
            <input
              className="form-control"
              type="email"
              name="Telefono"
              id="Telefono"
              placeholder="Teléfono *"
              onChange={handleonChange}
              maxLength={10}
              value={inputs.data.Telefono}
              title={''}
            />
          </div>
          <div className="col-12 col-sm-12">
            <label htmlFor="">¿Actualmente trabaja en Sendero?</label>
            <select
              name="actual_worker_in_sendero"
              id="actual_worker_in_sendero"
              className="form form-control"
              onChange={handleonChange}
              value={inputs.data.actual_worker_in_sendero}
            >
              <option value="1">SI</option>
              <option value="0">NO</option>
            </select>
          </div>
          <div className="col-12 col-sm-12">
            <label htmlFor="">¿Ha trabajado en Sendero en el pasado?</label>
            <select
              name="worked_in_sendero"
              id="worked_in_sendero"
              className="form form-control"
              onChange={handleonChange}
              value={inputs.data.worked_in_sendero}
            >
              <option value="1">SI</option>
              <option value="0">NO</option>
            </select>
          </div>
          <div className="col-12 col-sm-12">
            <textarea
              className="height_fixed"
              type="textarea"
              name="Mensaje"
              id="Mensaje"
              placeholder="Cuéntenos de forma breve su experiencia de ventas"
              onChange={handleonChange}
              value={inputs.data.Mensaje}
            ></textarea>
            <label htmlFor="" style={{ color: '#627171', opacity: '.7' }}>
              {inputs.data.Mensaje.length}
            </label>
          </div>
          <div className="form-group row">
            <div className="col-sm-12 text-center">
              {inputs.txtMsj !== '' ? (
                <Alert className={'alert ' + inputs.AlertClass}>
                  {inputs.txtMsj}
                </Alert>
              ) : (
                ''
              )}
            </div>
          </div>

          <div className="text-center col-12 col-sm-12">
            <Button
              className="btn btn-verde button"
              onClick={setForm}
              disabled={inputs.disabledButton}
            >
              {inputs.nameButton}
            </Button>
          </div>
        </div>
      </form>
    </>
  );
};

export default AgenteLibres;
