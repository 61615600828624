import React, { Component } from "react";
import { Collapse } from "reactstrap";
import { Link } from "react-router-dom";
import DefaultLink from "./DefaultLink";

class Url extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: false,
    };
    this.toggle = this.toggle.bind(this);
  }
  toggle(event) {
    event.preventDefault();
    this.setState((state) => ({ collapse: !state.collapse }));
  }
  render() {
    const {
      link,
      name,
      clasS,
      ShowMegaMenu,
      children,
      hidden_arrow,
      type_view,
    } = this.props;
    return (
      <div
        className={"item " + (clasS !== null ? clasS : "")}
        onClick={ShowMegaMenu}
      >
        {type_view === "MOBILE" ? (
          <>
            {typeof children !== "undefined" ? (
              <>
                <Link onClick={this.toggle}>
                  <p>{name}</p>
                </Link>
                <Collapse isOpen={this.state.collapse}>
                  {children.map((item, index) => (
                    <DefaultLink href={item.link} key={index}>
                      <p>{item.name}</p>
                    </DefaultLink>
                  ))}
                </Collapse>
              </>
            ) : (
              <DefaultLink href={link}>
                <p>{name}</p>
              </DefaultLink>
            )}
          </>
        ) : (
          <>
            <DefaultLink href={link}>
              <p>
                {name}
                {!hidden_arrow && typeof children !== "undefined" && (
                  <i className="fas fa-chevron-right"></i>
                )}
              </p>
            </DefaultLink>
            {typeof children !== "undefined" && (
              <div className="item__space_white">
                {children.map((item, index) => (
                  <div className="item" key={index}>
                    <DefaultLink href={item.link}>
                      <p>{item.name}</p>
                    </DefaultLink>
                  </div>
                ))}
              </div>
            )}
          </>
        )}
      </div>
    );
  }
}
export default Url;
