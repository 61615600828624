import React, { Component } from 'react';
import { Container } from 'reactstrap';
import Slider from '../../../components/SliderMobile';
import { HashLink as Link } from 'react-router-hash-link';
import IconUbicacionWhite from './../../../assets/images/icons/ico_ver_mapa.png';
import IconContratacion from './../../../assets/images/icons/mobile/doc.png';
import IconCremacion from './../../../assets/images/icons/Cremacion.png';
import IconSepultura from './../../../assets/images/icons/Sepultura.svg';
import Icon247white from './../../../assets/images/icons/247-white.png';
import IconContactenos from './../../../assets/images/icons/Contactenos.png';
import IconRedSendero from './../../../assets/images/icons/mobile/pin.png';
import shop from './../../../assets/images/shop.svg';
import user from './../../../assets/images/icons/misenderoperfil.svg';

import Posts from './../../../components/Posts';
import './Home.scss';
import Auth from '../../../pago-rapido/auth';
import BoxHome from './BoxHome';
import PesudoBanner from '../Mobile/PseudoBanner';

const data_home = [
  {
    name: 'LLÁMENOS',
    first: '600 736 3376',
    link: 'tel:600 736 3376',
    extra: 'green icon-xs',
    col: 6,
    type: 'contenedor box',
    target: 'a',
    icon: Icon247white,
  },
  {
    name: '',
    first: 'CONTÁCTENOS',
    link: '/contacto',
    extra: 'blue icon-xs',
    col: 6,
    type: 'contenedor box',
    target: 'l',
    icon: IconContactenos,
  },
  {
    name: 'COMPRA EN',
    first: 'LÍNEA',
    link: '/compra-en-linea',
    extra: 'pink icon-xs',
    col: 6,
    type: 'contenedor box',
    target: 'l',
    icon: shop,
  },
  {
    name: 'UBICACIÓN DE',
    first: 'SEPULTURA',
    link: '/ubicacion-de-sepulturas',
    extra: 'gray icon-xs',
    col: 6,
    type: 'contenedor box',
    target: 'l',
    icon: IconUbicacionWhite,
  },
  {
    name: 'MI ',
    first: 'SENDERO',
    link: '/mi-sendero',
    extra: 'blue icon-xs',
    col: 12,
    type: 'lineal mi-sendero-home',
    target: 'l',
    icon: user,
  },
  {
    name: 'pago-rapido',
    link: <Auth type="top-movil-home" popup={true} />,
    col: '12',
  },
  {
    name: 'CEMENTERIOS PARQUE ',
    first: 'SENDERO',
    link: '/parques',
    extra: 'blue icon-xs',
    col: 12,
    type: 'lineal',
    target: 'l',
    icon: IconRedSendero,
  },
  {
    name: '',
    first: 'SEPULTURA',
    link: '/sepultura',
    extra: 'gray icon-lg',
    col: 6,
    type: 'contenedor box',
    target: 'l',
    icon: IconSepultura,
  },
  {
    name: 'PARQUE',
    first: 'CREMATORIO',
    link: '/parque-crematorio',
    extra: 'gray icon-lg',
    col: 6,
    type: 'contenedor box',
    target: 'l',
    icon: IconCremacion,
  },
  {
    name: 'COTIZACIÓN ',
    first: 'ONLINE',
    link: '/sepultura/#SeleccioneSuPlan',
    extra: 'blue icon-xs',
    col: 12,
    type: 'lineal',
    target: 'l',
    icon: IconContratacion,
  },
];

class Home extends Component {
  componentDidMount() {
    document.title = 'Sendero | Home';
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div>
        <Slider />
        <br></br>
        <Container className="home-mobile">
          <div className="row">
            {data_home.map((item, index) => (
              <div className={'col-' + item.col} key={index}>
                {item.name === 'pago-rapido' ? (
                  item.link
                ) : item.target === 'a' ? (
                  <a href={item.link}>
                    <BoxHome {...item} />
                  </a>
                ) : (
                  <Link to={item.link}>
                    <BoxHome {...item} />
                  </Link>
                )}
              </div>
            ))}
          </div>
          {/* <img
            src={mas_informados_programa}
            alt="Mas Informados"
            class="img-fluid mt-5"
            style={{ cursor: 'pointer' }}
            onClick={(e) => {
              window.open('https://masinformados.sendero.cl/precios-sendero/', '_blanck');
            }}
          /> */}
          <PesudoBanner />
          <Posts />
        </Container>
      </div>
    );
  }
}
export default Home;
