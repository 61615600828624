import React, { useEffect } from 'react';
import Parques from './../assets/images/Contacto.jpg';
import BannerTop from './../components/BannerTop';
import { Container, Row, Col } from 'reactstrap';
import Icon247 from './../assets/images/icons/24-7.png';
import FormStep from '../components/Forms/Steps/FormStep';
import './formsteps.scss';
const FormCotizarStep = (props) => {
  useEffect(() => {
    document.title = 'Sendero | ' + props.title;
  }, [props.title]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <div>
      <Container fluid>
        {/* <BannerTop title1="" title2={props.title} image={Parques} /> */}
        <Container>
          {/* <br></br>
          <Row>
            <Col md={12} className="text-center">
              <div>
                <h2>
                  Estamos Aquí <strong>Cuando nos Necesite.</strong>
                </h2>
              </div>
            </Col>
          </Row>
          <br></br> */}
          <Row>
            <Col md="12">
              {/* <h5>
                <strong>
                  Déjenos sus datos y en breve nos pondremos en contacto con
                  usted.
                </strong>
              </h5>
              <br></br> */}
              <Row>
                <Col md="8" className='offset-md-2 container-steps'>
                  {/* <FormContacto
                    nameButton="ENVIAR"
                    destino_email="uobkukzx@robot.zapier.com"
                    type_form="Interesado en :"
                  /> */}
                  <FormStep {...props} />
                </Col>
                {/* <Col md="6" className="text-center">
                  <div className="contact">
                    <div>
                      <img src="" className="img img-247" alt="" />
                    </div>
                    <div>
                      <p className="contacto-contactenos">
                        <strong>CONTÁCTENOS LAS 24 HORAS</strong>
                      </p>
                      <p className="contacto-phone">
                        <img src={Icon247} alt="" />
                        <a href="tel:6007363376"> 600 736 3376</a>
                      </p>
                      <div className="contacto-siguenos">
                        <strong>Síganos</strong>
                        <div className="rrss">
                          <a href="https://www.facebook.com/Senderoparque/">
                            <i className="iconrrss fab fa-facebook-f"></i>{' '}
                          </a>
                          <a href="https://www.instagram.com/senderoparque/">
                            <i className="iconrrss fab fa-instagram"></i>{' '}
                          </a>
                          <a href="https://www.youtube.com/channel/UCv8fufJ35tJDP2E92NdHgww">
                            <i className="iconrrss fab fa-youtube"></i>{' '}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col> */}
              </Row>
            </Col>
          </Row>
          <br></br>
        </Container>
      </Container>
    </div>
  );
};

export default FormCotizarStep;
