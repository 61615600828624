import React, { Component } from "react";
import Url from "./Url";

class MenuMega extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMM: false,
    };
    this.ShowMegaMenu = this.ShowMegaMenu.bind(this);
  }
  ShowMegaMenu() {
    this.setState({
      showMM: !this.state.showMM,
    });
  }

  render() {
    const { ObjectData } = this.props;
    const { showMM } = this.state;
    return (
      <div className="col-md-1 d-none d-md-block">
        <div className="content-mm">
          <p onClick={this.ShowMegaMenu} className="open_click">
            <div className="burger">
              <span></span>
              <span></span>
              <span></span>
            </div>
            <span>Menú</span>
          </p>
          <div className={showMM ? "showMenu" : "hideMenu"}>
            <div className="close_click" onClick={this.ShowMegaMenu}>
              <span>X</span> Menú
            </div>
            <div className="opacity" onClick={this.ShowMegaMenu}>
              <div className="showMenu__space_white">
                {ObjectData && ObjectData.map((item, index) => (
                  <>
                    <Url
                      {...item}
                      clasS={item.clases}
                      key={index}
                      ShowMegaMenu={this.ShowMegaMenu}
                    />
                  </>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MenuMega;
