import React, { Component } from "react";
import { Link } from "react-router-dom";
class DefaultLink extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const DOMAINURL = "https://sendero.cl";
    const { children, href, toggle } = this.props;
    var link = href.replace(DOMAINURL, "");
    var type_target = link.includes("http") || link.includes("tel:");
    var active = link === window.location.pathname ? "active" : "";
    return (
      <>
        {type_target ? (
          <a href={link} className={active}>
            {children}
          </a>
        ) : (
          <Link to={link} className={active} onClick={toggle}>
            {children}
          </Link>
        )}
      </>
    );
  }
}
export default DefaultLink;
