import React, { Component } from "react";
import DefaultLink from "./DefaultLink";

class MenuTabs extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { ObjectData } = this.props;
    return (
      <>
        <div className="col-md-5">
          {ObjectData &&
            ObjectData.map((item, index) => (
              <DefaultLink href={item.link} key={index}>
                <div className="option">
                  {item.icon !== null ? (
                    <i className={item.icon}></i>
                  ) : (
                    <>{item.name}</>
                  )}
                </div>
              </DefaultLink>
            ))}
        </div>
      </>
    );
  }
}

export default MenuTabs;
