import React, { Component } from "react";
import { Navbar, Nav, NavItem } from "reactstrap";
import Logo from "./../../../Logo.jsx";
import SideBarLeft from "./SideBar";
import Url from "./Url";

class MenuHorizontal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: true,
    };
    this.handleMenuClick = this.handleMenuClick.bind(this);
  }
  handleMenuClick() {
    this.setState({ menuOpen: false });
  }
  render() {
    const { ObjectData, ObjectDataMobile } = this.props;
    const { menuOpen } = this.state;
    const ORIGIN_SITE = 0;
    return (
      <>
        <div className="main">
          <div className="d-none d-md-block">
            <div className={"menu"}>
              <div className="content">
                <Navbar bg="white" expand="lg">
                  <Logo add_class={"header"} />
                  <Nav navbar>
                    {ObjectData && ObjectData.map(
                      (item, index) =>
                        parseInt(item.origen) === parseInt(ORIGIN_SITE) && (
                          <NavItem>
                            <Url
                              {...item}
                              clasS={item.clases + " item-horizon"}
                              key={index}
                              hidden_arrow={true}
                            />
                          </NavItem>
                        )
                    )}
                  </Nav>
                </Navbar>
              </div>
            </div>
          </div>
          <div className="d-block d-md-none top-mobile">
            <div id="Sidebar" className={"_mobile"}>
              <div className={"menu"}>
                <div className="content">
                  <div className="menu-left">
                    <SideBarLeft
                      pageWrapId={"page-wrap"}
                      outerContainerId={"Sidebar"}
                      handleMenuClick={this.handleMenuClick}
                      menuOpen={menuOpen}
                      component={ObjectDataMobile.map((item, index) => (
                        <Url
                          {...item}
                          clasS={item.clases + " item-mobile"}
                          handleMenuClick={this.handleMenuClick}
                          key={index}
                          type_view={"MOBILE"}
                        />
                      ))}
                    />
                  </div>
                  <div id="page-wrap">
                    <Logo add_class={"header"} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default MenuHorizontal;
