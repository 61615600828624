import React, { useRef } from "react";
import ReactWizard from "../../Wizard/ReactWizard";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";

const FormStep = (props) => {
  const refWizard = useRef(null);
  const refStep1 = React.createRef();
  const refStep2 = React.createRef();
  const refStep3 = React.createRef();

  let steps = [
    {
      stepName: "Paso 1",
      ref: refStep1,
      // stepIcon: step1_no_active,
      // stepIconActive: step1_active,
      component: Step1,
      stepProps: props,
      keydata: "step1",
    },
    {
      stepName: "Paso 2",
      ref: refStep2,
      // stepIcon: step2_no_active,
      // stepIconActive: step2_active,
      component: Step2,
      stepProps: props,
      keydata: "step2",
    },
    {
      stepName: "Paso 3",
      ref: refStep3,
      // stepIcon: step3_no_active,
      // stepIconActive: step3_active,
      component: Step3,
      stepProps: props,
      keydata: "step3",
    },
  ];
  return (
    <ReactWizard
      ref={refWizard}
      steps={steps}
      navSteps
      title={""}
      description=""
      headerTextCenter
      validate
      finishButtonClasses="btn-wizard-finish"
      nextButtonClasses="btn-wizard-next"
      nextButtonText="Continuar"
      previousButtonText="Volver"
      color="primary"
      finishButtonText="Ir a Pagar"
    />
  );
};

export default FormStep;
