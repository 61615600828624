import React from 'react';
import { CodigoSpotiyfy } from '../../config';

import Spotify from 'react-spotify-embed';
const ReproductorSpotify = ({ props }) => {
  if (CodigoSpotiyfy !== props?.match?.params?.id) {
    return <></>;
  }

  return (
    <>
      <Spotify link="https://open.spotify.com/playlist/7rByfNyBdX4Yzt4KfzPrnn?si=QhXbBvUORTSp-wxJiPm07w&nd=1" />
    </>
  );
};

export default ReproductorSpotify;
